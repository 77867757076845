<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

        <!-- Table Top -->
        <b-row class="pt-2 pl-2 pr-2">  
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h4>Data Kambing Bunting</h4>
          </b-col>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            
          </b-col>
        </b-row>
        <hr/>
        <b-row class="pl-2 pr-2 pb-2">
          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
          <b-form-input
          v-model="searchQuery"
          class="d-inline-block mr-1"
          placeholder="Search..."
        />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              
              
            </div>
          </b-col>
        </b-row>

      

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchPregnant"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Jantan -->
        <template #cell(jantan)="data">
          <span>{{ data.item.jantan ? data.item.jantan : data.item.source_male }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          
          <feather-icon icon="EditIcon"  @click="setSelectedMating(data.item.id, data.item.betina); showModalUpdateStatus = !showModalUpdateStatus"/>
          
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalGoats"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
      v-model="showModalUpdateStatus"
      id="modal-update-status-mating"
      title="Perbarui Hasil Perkawinan"
      centered
    >
      <b-form>
        <b-form-group label="Induk Betina" label-for="female">
          <b-form-input
            id="female"
            v-model="selectedMating.female_animal"
            :disabled="true"
          />
        </b-form-group>

        <b-form-group
          label="Hasil Bunting"
          label-for="pregnant-result"
        >
          <v-select
            id="pregnant-result"
            v-model="selectedResultMating"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="resultOptions"
          />
        </b-form-group>

        <b-form-group
          :class="selectedResultMating.value == 'success' ? 'd-block' : 'd-none'"
          label="Tanggal beranak"
          label-for="birth_date_realization"
        >
          <b-form-datepicker
            id="birth_date_realization"
            placeholder="Pilih tanggal beranak"
            v-model="selectedMating.birth_date_realization"
            trim
          />
        </b-form-group>

        <b-form-group label="Tempat Lahir" label-for="place" :class="selectedResultMating.value == 'success' ? 'd-block' : 'd-none'">
          <b-form-input
            id="place"
            v-model="selectedMating.place"
          />
        </b-form-group>

        <b-form-group label="Jumlah anak" label-for="number_of_babies" :class="selectedResultMating.value == 'success' ? 'd-block' : 'd-none'">
          <b-form-input
            id="number_of_babies"
            v-model="selectedMating.number_of_babies"
          />
        </b-form-group>

        <b-form-group label="Jumlah Kolostrum" label-for="colostrum_volume" :class="selectedResultMating.value == 'success' ? 'd-block' : 'd-none'">
          <b-form-input
            id="colostrum_volume"
            v-model="selectedMating.colostrum_volume"
          />
        </b-form-group>

        <b-form-group label="Tanggal akhir nifas" label-for="end_of_postpartum" :class="selectedResultMating.value == 'success' ? 'd-block' : 'd-none'">
          <b-form-datepicker
            id="end_of_postpartum"
            v-model="selectedMating.end_of_postpartum"
          />
        </b-form-group>

        <b-form-group label="Keterangan" label-for="description" :class="selectedResultMating.value == 'failed' ? 'd-block' : 'd-none'">
          <b-form-input
            id="description"
            v-model="selectedMating.description"
          />
        </b-form-group>

        <b-form-group
        label="Tanggal Perkawinan Selanjutnya"
        label-for="next_mating_date"
      >
        <b-form-datepicker
          id="next_mating_date"
          placeholder="Pilih tanggal perkiraan lahir"
          v-model="selectedMating.next_mating_date"
          trim
        />
      </b-form-group>

        
        
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <b-button class="float-right ml-1 mr-1" size="md" variant="secondary" @click="onCancelModal()">
            Batal
          </b-button>
          <b-button 
            class="float-right" 
            size="md" 
            variant="primary" 
            @click="onSubmitModal()"
            v-if="$can('update', 'animal_mating')"
            >
            Simpan
          </b-button>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BForm,
  BFormGroup,
  BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useAnimalPregnantList from './useAnimalPregnantList'
import animalPregnantStoreModule from './animalPregnantStoreModule'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from "@/router";

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BForm,
    BFormGroup,
    BFormDatepicker,

    vSelect,
  },
  data() {
    return {
      resultOptions: [
        { label: 'Berhasil Melahirkan', value: 'success', disabled: false},
        { label: 'Gagal', value: 'failed', disabled:false},
      ],
    }
  },
  setup() {
    const toast = useToast()
    const PREGNANT_APP_STORE_MODULE_NAME = 'app-goat-pregnant'

    // Register module
    if (!store.hasModule(PREGNANT_APP_STORE_MODULE_NAME)) store.registerModule(PREGNANT_APP_STORE_MODULE_NAME, animalPregnantStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PREGNANT_APP_STORE_MODULE_NAME)) store.unregisterModule(PREGNANT_APP_STORE_MODULE_NAME)
    })


    const {
      fetchPregnant,
      tableColumns,
      perPage,
      currentPage,
      totalGoats,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Extra Filters
    } = useAnimalPregnantList()

    // update status bunting
    const showModalUpdateStatus = ref(false);
    const selectedResultMating= ref('');

    const blankSelectedMating = {
      id: null,
      female_animal: null,
      birth_date_realization: null,
      next_mating_date: null,
      description: null,
      place: 'SMKN 1 Tulung Klaten',
      number_of_babies: 0,
      colostrum_volume: 0,
      end_of_postpartum: null,
      pen_id: null,   
    }

    // set birthdate realization today
    blankSelectedMating.birth_date_realization = new Date();

    const selectedMating = ref(JSON.parse(JSON.stringify(blankSelectedMating)))
    
    const setSelectedMating = (id, betina) => {
      selectedMating.value.id = id
      selectedMating.value.female_animal = betina
    }

     // when click simpan status
     const onSubmitModal = () => {
        // check status 
       selectedMating.value.result = selectedResultMating.value.value  

      // store data via vuex
      if(selectedMating.value.result == 'success'){
        
        store.dispatch('app-goat-pregnant/updateMatingResult', 
        selectedMating.value,
        )
        
        .then((response) => {
          // reset selectedMating
          selectedMating.value = JSON.parse(JSON.stringify(blankSelectedMating))
          // close modal
          showModalUpdateStatus.value = !showModalUpdateStatus.value
          // show toast
          toast({
            component: ToastificationContent,
            props: {
              title: 'Hasil Perkawinan Berhasil Diperbarui',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          const giveBirth = response.data.data
          
          router.push({name: 'giveBirthDetail', params: { id: giveBirth.id }});
          refetchData()
          
        })

      }
      
      if(selectedMating.value.result == 'failed'){
        store.dispatch('app-goat-pregnant/updateStatus', 
        selectedMating.value,
        
        )
        
        .then(() => {
          // reset selectedMating
          selectedMating.value = JSON.parse(JSON.stringify(blankSelectedMating))
          // close modal
          showModalUpdateStatus.value = !showModalUpdateStatus.value
          // show toast
          toast({
            component: ToastificationContent,
            props: {
              title: 'Hasil Perkawinan Berhasil Diperbarui',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          // // refetch data
          refetchData()
        })
      }
        
    }

    const onCancelModal = () => {
        // check status 
        selectedResultMating.value = '';
        showModalUpdateStatus.value = !showModalUpdateStatus.value;
    }

    return {

      // modal
      showModalUpdateStatus,
      selectedMating,
      selectedResultMating,
      setSelectedMating,
      onSubmitModal,
      onCancelModal,

      fetchPregnant,
      tableColumns,
      perPage,
      currentPage,
      totalGoats,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
