import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useGoatsList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'tgl_kawin', sortable: true },
    { key: 'jantan', sortable: true },
    { key: 'betina', sortable: true },
    { key: 'tempat', sortable: true },
    { key: 'kandang', sortable: true },
    { key: 'hasil', sortable: true },
    { key: 'hpl', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalGoats = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalGoats.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery,], () => {
    refetchData()
  })

  const mapServiceMatingToModel = ({id, jantan, betina,place, tanggal, hasil, hpl, kandang, source_male}) => ({
    id,
    tgl_kawin: tanggal,
    jantan,
    betina,
    tempat: place,
    hasil,
    hpl,
    kandang,
    source_male,
  })

  const fetchPregnant = (ctx, callback) => {
    store
      .dispatch('app-goat-pregnant/fetchPregnant', {
        result: 'positive'
      })
      .then(response => {
        const data = response.data.data
        const { total } = response.data.data
        const dataMapped = data.map(mapServiceMatingToModel)
        callback(dataMapped)
        totalGoats.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching goats list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchPregnant,
    tableColumns,
    perPage,
    currentPage,
    totalGoats,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    refetchData,

  }
}
